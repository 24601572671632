<template>
  <chi-tiet-yeu-cau-bo-sung />
</template>
<script>
import ChiTietYeuCauBoSung from '@/modules/xu-ly-ho-so/components/pages/yeu-cau-bo-sung/ChiTietYeuCauBoSung.vue'

export default {
  components: {
    ChiTietYeuCauBoSung,
  },
}
</script>
