<template>
  <b-card
    no-body
    class="mb-0"
  >
    <div>
      <b-tabs card>
        <b-tab
          title="THÔNG TIN HỒ SƠ"
          active
        >
          <div class="">
            <div class="custom-grid-xl-container">
              <div
                v-for="item in listTTHS"
                :key="item.id"
                :class="item.class"
                class="mb-1"
              >
                {{ item.name }} : {{ data[item.field] }}
              </div>
            </div>
            <app-table
              class="mb-1"
              :columns="columns"
              :table-data="tableData"
            />
            <h4>Thông tin giấy tờ khác</h4>
            <div class="mb-1">
              Không có
            </div>
            <h4>Thông tin giấy tờ tiếp nhận bổ sung</h4>
            <div class="mb-1">
              Không có
            </div>
            <h4>Thông tin hóa đơn thanh toán phí, lệ phí</h4>
            <div class="mb-1">
              Không có
            </div>
            <div class="d-sm-flex justify-content-end">
              <button
                class="compact ui btn-danger button"
              >
                Quay lại
              </button>
            </div>
          </div>
        </b-tab>
        <b-tab title="THÔNG TIN BỔ SUNG">
          <div class="">
            <app-table
              class="mb-1"
              :columns="columns"
              :table-data="tableData"
            />
            <h4>Thông tin giấy tờ khác</h4>
            <div class="mb-1">
              Không có
            </div>

            <div class="d-sm-flex justify-content-end">
              <button
                class="compact ui btn-primary button mr-1"
              >
                Tiếp nhận bổ sung
              </button>
              <button
                class="compact ui btn-primary button mr-1"
              >
                Trả kết quả không giải quyết hồ sơ
              </button>
              <button
                class="compact ui btn-danger button"
              >
                Đóng
              </button>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>

  </b-card>
</template>
<script>
import {
  BCard,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import addCssSemantic from '@/mixins/mixins'
import AppTable from '@/components/AppTable.vue'

export default {
  name: 'ChiTietHoSo',
  components: {
    BCard,
    BTabs,
    BTab,
    AppTable,
  },
  mixins: [addCssSemantic],
  data() {
    return {
      listTTHS: [
        {
          id: 1,
          name: 'Ngày nộp',
          field: 'date',
          class: 'grap-3',
        },
        {
          id: 2,
          name: 'Người nộp',
          field: 'people',
          class: 'grap-3',
        },
        {
          id: 3,
          name: 'Chủ hồ sơ',
          field: 'name',
          class: 'grap-3',
        },
        {
          id: 4,
          name: 'Ngày tiếp nhận',
          field: 'name',
          class: 'grap-3',
        },
        {
          id: 5,
          name: 'Số CMND/Hộ chiếu',
          field: 'name',
          class: 'grap-3',
        },
        {
          id: 6,
          name: 'Thời gian giải quyết',
          field: 'name',
          class: 'grap-3',
        },
        {
          id: 7,
          name: 'Địa chỉ',
          field: 'name',
          class: 'grap-3 word-break',
        },
        {
          id: 8,
          name: 'Email',
          field: 'name',
          class: 'grap-3',
        },
        {
          id: 9,
          name: 'Điện thoại',
          field: 'name',
          class: 'grap-3',
        },
      ],
      data: {
        date: '21/03/2022 15:34:03',
        people: 'Nguyễn Văn A',
        name: 'Nguyễn Văn A',
      },
      columns: [
        {
          field: '',
          key: 'STT',
          title: 'STT',
          align: 'left',
          width: 50,
          renderBodyCell: ({ rowIndex }) => rowIndex + 1,
        },
        {
          field: 'name',
          key: 'name',
          title: 'Tên giấy tờ',
          align: 'left',
        },
        {
          field: 'main',
          key: 'main',
          title: 'Bản chính',
          align: 'right',
          width: 150,
        },
        {
          field: 'copy',
          key: 'copy',
          title: 'Bản sao',
          align: 'right',
          width: 150,
        },
        {
          field: 'photo',
          key: 'photo',
          title: 'Bản photo',
          align: 'right',
          width: 150,
        },
        {
          field: 'file',
          key: 'file',
          title: 'File đính kèm',
          align: 'left',
        },
      ],
      tableData: [
        {
          id: 1,
          name: 'Tờ khái theo mẫu quy định',
          main: 1,
          copy: 0,
          photo: 0,
          file: [],
        },
        {
          id: 2,
          name: 'Văn bản ủy quyền cho cá nhân thực hiện thủ tục liên quan đến đăng ký doanh nghiệp. Văn bản ủy quyền này không bắt buộc phải công chứng. chứng nhận',
          main: 1,
          copy: 0,
          photo: 0,
          file: [],
        },
        {
          id: 3,
          name: 'Bản sao giấy tờ pháp lý của cá nhân được ủy quyền',
          main: 0,
          copy: 1,
          photo: 0,
          file: [],
        },
      ],
    }
  },
}
</script>
